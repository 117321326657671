import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'main',
        component: () => import('@/views/userView/mainComponent.vue'),
    },
    {
        path: '/car_park',
        name: 'carPark',
        component: () => import('@/views/userView/carParkComponent.vue'),
    },
    {
        path: '/routes',
        name: 'routes',
        component: () => import('@/views/userView/ourRoutesComponent.vue'),
    },
    {
        path: '/routes/irregional',
        name: 'routes_irregional',
        component: () => import('@/views/userView/ourRoutesComponent.vue'),
    },
    {
        path: '/route/:id',
        name: 'routes_detail_page',
        component: () => import('@/views/userView/routeDetailPageComponent.vue'),
    },
    {
        path: '/transportation_rules',
        name: 'transportationRules',
        component: () => import('@/views/userView/transportationRulesComponent.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/userView/contactComponent.vue'),
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('@/views/adminView/adminComponent.vue'),
        meta: { requiresLogin: true }
    },
    {
        path: '/login',
        name: 'admin_login',
        component: () => import('@/views/adminView/loginPage.vue'),
    },
    {
        path: '/reset_password',
        name: 'reset_password',
        component: () => import('@/views/adminView/resetPassword.vue')
    },
    {
        path: '/reset_password/:code',
        name: 'reset_password_code',
        component: () => import('@/views/adminView/inputNewPassword.vue'),
    },
    { path: '*', redirect: '/404' },
    {
        path: '/404',
        name: 'not_found_page',
        component: () => import("@/components/User/404.vue")
    }
    // {
    //     path: '/rules_and_general_terms',
    //     name: 'generalTerms',
    //     component: () => import('@/views/forUser/generalTermAndRules.vue'),
    // },
    // {
    //     path: '/irregular_transportation',
    //     name: 'irrgularTransportation',
    //     component: () => import('@/views/userView/irregularTransportationComponent.vue'),
    // },
    // {
    //     path: '/route-search',
    //     name: 'route_search_page',
    //     component: () => import('@/views/userView/routeSearchComponent.vue'),
    // },
    // {
    //     path: '/passanger',
    //     name: 'ticket_booking',
    //     component: () => import('@/views/userView/bookingTicketComponent.vue')
    // },
    // {
    //     path: '/edit_profile',
    //     name: 'editProfile',
    //     component: () => import('@/components/forUser/editUserProfile.vue')
    // },
    // {
    //     path: '/ticket',
    //     name: 'my_ticket_mobile',
    //     component: () => import('@/components/forUser/myTicket/myTicketDrawer.vue')
    // },
    // {
    //     path: '/bus_qr/:bus_id',
    //     name: 'bus_by_qr_code',
    //     component: () => import("@/views/forUser/busByQrCode.vue")
    // },
    // {
    //     path: '/order_qr/:order_id',
    //     name: 'order_by_qr_code',
    //     component: () => import("@/views/forUser/orderByQrCode.vue")
    // },
    // {
    //     path: '/other',
    //     name: 'other_route'
    // },
    // {
    //     path: '/payment/status',
    //     name: 'success_page',
    //     redirect: '/'
    // },
];
const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresLogin) && store.getters.loggedUser == null) {
        next({ name: 'admin_login' })
    } else if (to.matched.some(record => record.meta.requiresLogin) && store.getters.loggedUser.role_id == 3) {
        next({ name: 'main' })
    }
    else {
        next();
    }
})

export default router;
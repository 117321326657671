<template>
  <v-footer
    :height="$vuetify.breakpoint.smAndDown ? 'max-content' : '400px'"
    color="#0F1F2F"
  >
    <div
      style="position: absolute; top: 0; left: 10%"
      :style="$vuetify.breakpoint.smAndDown ? 'width: 45px;' : 'width: 90px;'"
    >
      <v-row no-gutters>
        <div class="elipse" v-for="i in 12" :key="i"></div>
      </v-row>
    </div>
    <v-main :key="key">
      <v-container>
        <v-row
          no-gutters
          align="start"
          style="z-index: 10"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin-top: 40px;'
              : 'margin-top: 80px;'
          "
        >
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            :style="$vuetify.breakpoint.smAndDown ? 'text-align:center' : ''"
          >
            <img
              src="@/assets/img/logoForFooter.png"
              alt="logo"
              :width="$vuetify.breakpoint.smAndDown ? '140px' : '190px'"
              :height="$vuetify.breakpoint.smAndDown ? '48px' : '70px'"
            />
            <v-row
              no-gutters
              align="center"
              style="margin-top: 30px"
              :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
            >
              <a
                v-for="link in socialLinks"
                :key="link.id"
                target="_blank"
                class="icon"
                :href="link.value"
              >
                <img
                  width="24px"
                  height="24px"
                  style="object-fit: contain"
                  :src="link.image.path"
                  :alt="link.image.path"
                />
              </a>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'text-align:left; margin-top: 67px;'
                : ''
            "
          >
            <p class="sectionName">Телефони диспетчерів</p>
            <p
              class="sectionText"
              v-for="number in dispatcherPhoneNumbers"
              :key="number.id"
            >
              <a :href="`tel:${number.value}`" style="color: #b5b5b5">
                {{ number.value }}
              </a>
            </p>
          </v-col>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'text-align:left; margin-top: 32px;'
                : ''
            "
          >
            <p class="sectionName">Контактна інформація</p>
            <p class="sectionText">
              <a :href="`mailto:${email.value}`" style="color: #b5b5b5">{{
                email.value
              }}</a>
            </p>
            <p class="sectionText">{{ address.value }}</p>
          </v-col>
          <v-col
            cols="12"
            xl="3"
            lg="3"
            md="3"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'text-align:left; margin-top: 32px;'
                : ''
            "
          >
            <p class="sectionName">Додаткова інформація</p>
            <router-link to="/transportation_rules">
              <p class="sectionText" style="color: #ffffff">
                Правила перевезення
              </p>
            </router-link>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          justify="center"
          class="copyrightText"
          style="margin-top: 64px !important; padding-bottom: 80px"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <v-col cols="12" style="text-align: center">
            <span>
              © A&MExpress 2023
              <span v-if="new Date().getFullYear() > 2023"
                >- {{ new Date().getFullYear() }}</span
              ></span
            ></v-col
          >
          <v-col cols="12" style="margin: 12px 0px">
            <v-row no-gutters justify="center">
              <div
                style="
                  border: 1px solid #2c3535;
                  width: 15px;
                  height: 1px;
                  margin: 5px;
                "
              ></div>
            </v-row>
          </v-col>
          <v-col cols="12" style="text-align: center">
            <v-row no-gutters align="center" justify="center">
              <span>Developed by</span>
              <img
                src="@/assets/img/BMaxLogo.png"
                width="80px"
                height="40px"
                style="object-fit: cover; margin-left: 8px"
                alt="bmax logo"
              />
            </v-row>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          align="center"
          justify="center"
          class="copyrightText"
          v-else
        >
          <span>
            © A&MExpress 2023
            <span v-if="new Date().getFullYear() > 2023"
              >- {{ new Date().getFullYear() }}</span
            ></span
          >
          <div
            style="
              border: 1px solid #2c3535;
              width: 1px;
              height: 15px;
              margin: 5px;
            "
          ></div>
          <span>Developed by</span>
          <img
            src="@/assets/img/BMaxLogo.png"
            width="80px"
            height="40px"
            style="object-fit: cover; margin-left: 8px"
            alt="bmax logo"
          />
        </v-row>
      </v-container>
    </v-main>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterComponent",
  data: () => ({
    dispatcherPhoneNumbers: [],
    address: "",
    email: "",
    socialLinks: [],
    key: 0,
  }),
  mounted() {
    this.setLists();
  },
  methods: {
    setLists() {
      this.dispatcherPhoneNumbers = this.settingList.filter(
        (setting) => setting.type == "dispatchers_phone_number"
      );
      this.address =
        this.settingList.filter((setting) => setting.key == "address")?.[0] ||
        "";
      this.email =
        this.settingList.filter(
          (setting) => setting.key == "support_email"
        )?.[0] || "";
      this.socialLinks = this.settingList.filter(
        (setting) => setting.type == "socials"
      );
      this.key++;
    },
  },
  computed: {
    ...mapGetters(["settingList"]),
  },
  watch: {
    settingList: {
      deep: true,
      handler() {
        this.setLists();
      },
    },
  },
};
</script>

<style scoped>
.icon {
  margin-right: 32px;
}
.icon:nth-child(4n) {
  margin-right: 0px;
}
.copyrightText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #eeeeee;
  margin-top: 100px !important;
}
@media only screen and (max-width: 1023px) {
  .elipse {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #172534;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 1024px) {
  .elipse {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #172534;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.elipse:nth-child(3n) {
  margin-right: 0px;
}
.sectionName {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #eeeeee;
  margin-bottom: 15px;
}
.sectionText {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #b5b5b5;
  margin-bottom: 10px;
}
</style>